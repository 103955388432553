import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './Logo.module.scss';

const Logo = ({ size, shrink, alternative }) => {
  const { primary, secondary } = useStaticQuery(
    graphql`
      query {
        primary: file(relativePath: { eq: "logo-monolit.svg" }) {
          publicURL
        }
        secondary: file(relativePath: { eq: "logo-monolit-text.svg" }) {
          publicURL
        }
      }
    `
  );

  let image = alternative ? secondary.publicURL : primary.publicURL;

  size = size ? styles[size] : styles.Large;

  let logoClasses = [styles.Logo, size];
  if (shrink) {
    logoClasses.push(styles.Shrink);
  }

  return <img src={image} alt="logo" className={logoClasses.join(' ')} />;
};

export default Logo;
