import React, { useState, useEffect } from 'react';
import { Link } from 'gatsby';

import styles from './Header.module.scss';

import Logo from '../Logo/Logo';
import Navigation from '../Navigation/Navigation';

const Header = props => {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    // componentDidMount
    window.addEventListener('scroll', handleScroll);

    // componentWillUnmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleScroll = () => {
    const fromTop = window.pageYOffset;

    if (fromTop > 120) {
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  let headerClasses = [styles.Masthead];
  if (isSticky) {
    headerClasses.push(styles.Sticky);
  }

  return (
    <header className={headerClasses.join(' ')}>
      <div className={`${styles.Wrapper} container flat`}>
        <Link to="/">
          <Logo size="small" shrink={isSticky} />
        </Link>

        <Navigation shrink={isSticky} />
      </div>
    </header>
  );
};

export default Header;
