import React from 'react';

import styles from './MenuButton.module.scss';

const MenuButton = props => {
  let classes = [styles.Hamburger, styles.HamburgerSpring];

  if (props.isActive) {
    classes.push(styles.IsActive);
  }

  if (props.shrink) {
    classes.push(styles.Shrink);
  }

  return (
    <button
      className={classes.join(' ')}
      type="button"
      onClick={() => props.toggleMenu()}
      aria-label="Toggle navigation menu"
    >
      <span className={styles.HamburgerBox}>
        <span className={styles.HamburgerInner}></span>
      </span>
    </button>
  );
};

export default MenuButton;
