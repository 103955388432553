import React from 'react';
import PropTypes from 'prop-types';

import Header from '../Header/Header';
import '../../sass/style.scss';

import Footer from '../Footer/Footer';

if (typeof window !== 'undefined') {
  require('smooth-scroll')('a[href*="#"]');
}

const Layout = ({ children }) => {
  return (
    <>
      <Header />
      <div className="layout-inner">
        <main>{children}</main>
      </div>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
