import React from 'react';

import styles from './BottomStrip.module.scss';

const BottomStrip = props => {
  return (
    <div className={styles.BottomStrip}>
      <span className={styles.Notice}>
        Developed by{' '}
        <a href="https://devnet.hr" target="_blank" rel="noopener noreferrer">
          Devnet
        </a>{' '}
        <span className={styles.Devider}>|</span> Copyright ©{' '}
        {new Date().getFullYear()} Monolit d.o.o. Sva prava pridržana.{' '}
      </span>
    </div>
  );
};

export default BottomStrip;
