import React from 'react';
import BackgroundImage from 'gatsby-background-image';

const Aux = ({ tag, fluidImage, className, overlay, imageURL, ...props }) => {
  tag = tag ? tag : 'section';
  let html = null;

  const overlayHTML = overlay ? (
    <span className={`overlay ${overlay}`}></span>
  ) : null;

  let atts = {
    className: className,
  };

  if (imageURL) {
    atts = {
      ...atts,
      style: {
        backgroundImage: `url(${imageURL})`,
        backgroundRepeat: 'no-repeat',
      },
    };
  }

  const innerElements = (
    <>
      {props.children}
      {overlayHTML}
    </>
  );

  switch (tag) {
    case 'section':
      html = <section {...atts}>{innerElements}</section>;
      break;
    case 'div':
      html = <div {...atts}>{innerElements}</div>;
      break;
    case 'span':
      html = <span {...atts}>{innerElements}</span>;
      break;
    default:
      html = null;
  }

  if (fluidImage) {
    html = (
      <BackgroundImage Tag={tag} className={className} fluid={fluidImage}>
        {props.children}
        {overlayHTML}
      </BackgroundImage>
    );
  }

  return html;
};

export default Aux;
