import React, { useState } from 'react';
import { useStaticQuery, graphql, Link } from 'gatsby';
import MenuButton from '../UI/Buttons/MenuButton/MenuButton';
import styles from './Navigation.module.scss';

const Navigation = props => {
  const { navigationJson } = useStaticQuery(
    graphql`
      query {
        navigationJson {
          primaryNavigation {
            id
            name
          }
        }
      }
    `
  );

  const [toggleMenu, setToggleMenu] = useState(false);

  const toggleMenuHandler = () => {
    setToggleMenu(!toggleMenu);
  };

  let navItems = null;
  navItems = navigationJson.primaryNavigation.map(item => (
    <Link to={'/' + item.id} className={styles.NavItem} key={item.id}>
      {item.name}
    </Link>
  ));

  const navStyles = [styles.Nav];
  if (toggleMenu) {
    navStyles.push(styles.Open);
  }

  return (
    <>
      <nav className={navStyles.join(' ')}>{navItems}</nav>
      <MenuButton
        toggleMenu={toggleMenuHandler}
        isActive={toggleMenu}
        shrink={props.shrink}
      />
    </>
  );
};

export default Navigation;
