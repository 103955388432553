import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import styles from './ContactInfo.module.scss';

const ContactInfo = ({ type, title }) => {
  const { allFile, generalInfo } = useStaticQuery(
    graphql`
      query {
        allFile(
          sort: { fields: name, order: DESC }
          filter: { relativeDirectory: { eq: "icons/contact" } }
        ) {
          edges {
            node {
              id
              name
              publicURL
            }
          }
        }

        generalInfo: allGeneralJson {
          nodes {
            company
            description
            address
            city
            country
            zip
            email
            tel
            formattedAddress
          }
        }
      }
    `
  );

  const info = generalInfo.nodes[0];
  const email = info.email;
  const tel = info.tel;
  const formattedAddress = info.formattedAddress;

  let phoneIcon = allFile.edges.filter(item => 'phone' === item.node.name);
  let mailIcon = allFile.edges.filter(item => 'mail' === item.node.name);
  let pinIcon = null;
  let pinHTML = null;

  let titleHTML = null;

  let classes = [styles.InfoWrapper];

  if (type) {
    classes.push(styles[type]);

    if (type === 'vertical') {
      phoneIcon = allFile.edges.filter(
        item => 'phone-white' === item.node.name
      );
      mailIcon = allFile.edges.filter(item => 'mail-white' === item.node.name);
      pinIcon = allFile.edges.filter(item => 'pin-white' === item.node.name);

      pinHTML = (
        <img
          src={pinIcon[0].node.publicURL}
          className={styles.Pin}
          alt="pin icon"
        />
      );

      titleHTML = <h3 className="bottom-line">{title}</h3>;
    }
  }

  return (
    <div className={classes.join(' ')}>
      {titleHTML}
      <div className={styles.Info}>
        {pinHTML}
        <span
          className={styles.InfoText}
          dangerouslySetInnerHTML={{ __html: formattedAddress }}
        ></span>
      </div>
      <div className={styles.Info}>
        <img src={phoneIcon[0].node.publicURL} alt="phone icon" />
        <span className={styles.InfoText}>
          <a href={`tel:${tel.replace(/ /g, '')}`}>{tel}</a>
        </span>
      </div>
      <div className={styles.Info}>
        <img src={mailIcon[0].node.publicURL} alt="mail icon" />
        <span className={styles.InfoText}>
          <a href={`mailto:${email}`}>{email}</a>
        </span>
      </div>
    </div>
  );
};

export default ContactInfo;
