import React from 'react';
import { Link, useStaticQuery, graphql } from 'gatsby';
import Aux from '../../Aux/Aux';
import Logo from '../../Logo/Logo';
import ContactInfo from '../../Contact/ContactInfo/ContactInfo';
import styles from './UpperFooter.module.scss';

const UpperFooter = props => {
  const { bgImage, generalInfo } = useStaticQuery(
    graphql`
      query {
        bgImage: allFile(
          filter: { relativePath: { eq: "backgrounds/rope.jpg" } }
        ) {
          edges {
            node {
              childImageSharp {
                fluid {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
        }

        generalInfo: allGeneralJson {
          nodes {
            description
          }
        }
      }
    `
  );

  const image = bgImage.edges[0].node.childImageSharp.fluid;
  const info = generalInfo.nodes[0];

  return (
    <Aux
      tag="div"
      className="background-overlay"
      fluidImage={image}
      overlay="dark"
    >
      <div className={['container', styles.Container].join(' ')}>
        <div className="grid">
          <div className="col-md-4">
            <Logo size="medium" alternative />
            <p>{info.description}</p>
          </div>
          <div className="col-md-4">
            <ContactInfo type="vertical" title="Kontakt" />
          </div>
          <div className="col-md-4">
            <h3 className="bottom-line">Informacije</h3>
            <Link to="/" className={styles.NavLink}>
              Naslovnica
            </Link>
            <Link to="/o-nama" className={styles.NavLink}>
              O nama
            </Link>
            <Link to="/usluge" className={styles.NavLink}>
              Usluge
            </Link>
            <Link to="/galerija" className={styles.NavLink}>
              Galerija
            </Link>
            <Link to="/kontakt" className={styles.NavLink}>
              Kontakt
            </Link>
            <Link to="/pravila-privatnosti" className={styles.NavLink}>
              Pravila privatnosti
            </Link>
          </div>
        </div>
      </div>
    </Aux>
  );
};

export default UpperFooter;
